import React, { lazy, useEffect, useRef, useState } from "react";
import ShareModal from "../ShareModal";

const MatchCard = lazy(() => import("../../components/Matches/MatchCard"));

export const Matches = () => {
  const [finishedMatches, setFinishedMatches] = useState([]);
  const [matches, setMatches] = useState([]);
  const [liveScore, setLiveScore] = useState({});
  const intervalsRef = useRef([]);
  const [matchType, setMatchType] = useState("live");
  const [competitions, setCompetitions] = useState([]);
  const [finishedCompetitions, setFinishedCompetitions] = useState([]);
  const [curCompetition, setCurCompetition] = useState("All");
  const [curFinishedCompetition, setCurFinishedCompetition] = useState("All");
  const [finishedOffset, setfinishedOffset] = useState(0);
  const maxFinished = 50;
  const [loadingMore, setLoadingMore] = useState(true);
  const [quickfireEnabled, setQuickfireEnabled] = useState({
    enabled: false,
    competitionToLoad: "",
  });
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);

  const [isTraderEnabled, setIsTraderEnabled] = useState(false);
  const [isSquaresEnabled, setIsSquaresEnabled] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    "x-api-key": process.env.API_KEY,
  };

  const isQuickFireEnabled = async () => {
    const clientTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date();
    const clientDate = date.toISOString().split("T")[0];
    const response = await fetch(
      `${process.env.API_URL}/quickfire/enabled?clientTZ=${clientTimezoneName}&clientDate=${clientDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY,
        },
      }
    );
    if (response.status === 200) {
      setQuickfireEnabled(await response.json());
    }
  };

  useEffect(() => {
    isQuickFireEnabled();
    areGamesEnabled();
  }, []);

  const areGamesEnabled = async () => {
    const response = await fetch(`${process.env.API_URL}/isgameenabled/ALL`, {
      method: "GET",
      headers: headers,
    });
    if (response.status !== 200) {
      return;
    }
    const data = await response.json();
    const traderIndex = data.findIndex((x) => x.game_name === "Trader");
    if (traderIndex > -1) {
      setIsTraderEnabled(data[traderIndex].is_enabled);
    }
    const squaresIndex = data.findIndex((x) => x.game_name === "Squares");
    if (squaresIndex > -1) {
      setIsSquaresEnabled(data[squaresIndex].is_enabled);
    }
  };

  async function getScoreCard(match_id) {
    try {
      const response = await fetch(
        `${process.env.API_URL}/scorecard/${match_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );

      if (response.status === 200) {
        return await response.json();
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      //console.error('Error fetching data:', error);
      return null;
    }
  }

  async function fetchFinishedComps() {
    try {
      const res = await fetch(
        `${process.env.API_URL}/fixturelist/completedCompetitions`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      if (res.status === 200) {
        const xdata = await res.json();
        let fc = [];
        for (let i = 0; i < xdata.length; i++) {
          fc.push(xdata[i].competition);
        }
        setFinishedCompetitions(fc);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  }

  async function fetchFinished(offset, tournamentFilter) {
    setLoadingMore(true);
    //let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
    try {
      const res = await fetch(
        `${process.env.API_URL}/fixturelist/completed/${offset}/${maxFinished}/${tournamentFilter}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      let compbuild = [];
      if (res.status === 200) {
        const xdata = await res.json();
        const newMatches = [];
        for (const match of xdata) {
          match.id = match.match_id;
          let matchStart = new Date(match.local_time);
          const options = {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour12: false,
          };
          match.formattedDate = matchStart.toLocaleString("en-GB", options);
          let scores = [];
          let name = match.description.split(",");
          let teams = name[0].split(" v ");
          match.teams = teams;
          match.competition = name[1].trim();
          if (!compbuild.includes(match.competition)) {
            compbuild.push(match.competition);
          }
          const scoreGridData = match.scoregrid;
          if (scoreGridData) {
            let inn = scoreGridData.inns_now;
            let ban = scoreGridData.bat_now;
            for (let i = inn; i > 0; i = i - 1) {
              let inkey = "inns" + i;

              if (ban == teams[0]) {
                scores[0] = {
                  team: ban,
                  score:
                    scoreGridData[inkey].runs + "/" + scoreGridData[inkey].wkts,
                };
              } else {
                scores[1] = {
                  team: ban,
                  score:
                    scoreGridData[inkey].runs + "/" + scoreGridData[inkey].wkts,
                };
              }
              if (ban === scoreGridData.bat_now) {
                ban = scoreGridData.bowl_now;
              } else {
                ban = scoreGridData.bat_now;
              }
            }
            match.scores = scores;
          }
          newMatches.push(match);
        }
        setFinishedMatches((prevMatches) => [...prevMatches, ...newMatches]);
        setLoadingMore(false);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  }

  async function fetchScoreCard(match_id, teams) {
    try {
      const res = await fetch(`${process.env.API_URL}/scorecard/${match_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY,
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.length > 0) {
          let inns = [];
          inns[1] = data[0].score.inns1;
          inns[2] = data[0].score.inns2;
          inns[3] = data[0].score.inns3;
          inns[4] = data[0].score.inns4;
          let homeScore = "";
          let awayScore = "";
          let cinns = data[0].score.inns_now;
          if (teams[0] === data[0].score.bat_now) {
            homeScore = inns[cinns];
            if (cinns > 1) {
              awayScore = inns[cinns - 1];
            }
          } else {
            awayScore = inns[cinns];
            if (cinns > 1) {
              homeScore = inns[cinns - 1];
            }
          }
          setLiveScore((prevState) => ({
            ...prevState,
            [match_id]: {
              homescore: homeScore,
              awayscore: awayScore,
              batnow: data[0].score.bat_now,
            },
          }));
        }
      }
    } catch (error) {
      //console.error('Network error:', error);
    }
  }

  async function fetchData() {
    const res = await fetch(
      process.env.API_URL + "/fixturelist?match_id_contains=LIVE",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY,
        },
      }
    );
    const data = await res.json();
    const mData = data.matches;
    let matchBuild = [];
    let compbuild = [];
    mData.forEach((match) => {
      let x = match.competition.trim();
      if (compbuild.indexOf(x) < 0) {
        compbuild.push(x);
      }
      let matchStart = new Date(match.start);
      let today = new Date();
      match.localTime = matchStart.toLocaleString("en-US", {
        weekday: "short",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      let diff = matchStart - today;

      if (diff < 0 && match.localTime !== "IN-PLAY") {
        match.diff = "Delayed";
      } else {
        let mm = Math.floor(diff / 1000 / 60);
        let hh = Math.floor(diff / 1000 / 60 / 60);
        let days = Math.floor(hh / 24);
        if (days > 0) {
          let remainingHours = hh % 24;
          match.diff = days + "D " + remainingHours + "H";
        } else if (hh > 0) {
          match.diff = hh + "H";
        } else {
          match.diff = mm + "M";
        }
      }
      matchStart.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      let name = match.name.split(",");
      let teams = name[0].split(" v ");
      if (
        match.status.substring(0, 17) === "Match in Progress" ||
        match.status.substring(0, 13) === "Break in Play" ||
        match.status.substring(0, 16) === "Innings Complete" ||
        match.status.substring(0, 8) === "Play to "
      ) {
        match.localTime = "IN-PLAY";
        fetchScoreCard(match.id, teams);
        const intervalId = setInterval(() => {
          fetchScoreCard(match.id, match.teams);
        }, 30000); // 30 seconds interval
        intervalsRef.current.push(intervalId);
      } else if (
        match.status.substring(0, 14) === "Match Complete" ||
        match.status.substring(0, 12) === "Event Closed"
      ) {
        match.localTime = "Match Complete";
        fetchScoreCard(match.id, teams);
      }
      match.wal = match.watchalong !== null;
      if (matchStart >= today || match.localTime === "IN-PLAY") {
        if (match.end === "") {
          match.teams = teams;
          matchBuild.push(match);
        }
      }
    });
    matchBuild.sort((a, b) => new Date(a.start) - new Date(b.start));
    setCompetitions(compbuild.sort());
    setMatches(matchBuild);
  }

  const handleCompetitionChange = (event) => {
    setCurCompetition(event.target.value);
  };
  const handleFinishedCompetitionChange = (event) => {
    setCurFinishedCompetition(event.target.value);
    setFinishedMatches([]);
    setfinishedOffset(0);
    fetchFinished(0, event.target.value);
  };
  const handleLoadMore = () => {
    let offset = finishedOffset + maxFinished;
    setfinishedOffset(offset);
    fetchFinished(offset, curFinishedCompetition);
  };
  useEffect(() => {
    fetchData();
    return () => {
      intervalsRef.current.forEach(clearInterval);
    };
  }, []);
  useEffect(() => {
    fetchFinishedComps();
    fetchFinished(0, "All");
  }, []);
  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "Upcoming Matches | Cricket8";
    };
    updatePageTitle();
    return () => {};
  }, []);

  return (
    <div id="content" className="min-h-[75vh] mt-[0px] content-t">
      {/*<div className='flex justify-between'>*/}
      {/*    {quickfireEnabled.enabled && (*/}
      {/*        <Link to='/games' className='mt-[-30px] mr-[-23px] z-10'>*/}
      {/*            <img*/}
      {/*                className='h-[150px]'*/}
      {/*                src='/img/public/play-trivia-sticker.png'*/}
      {/*                alt=''*/}
      {/*            />*/}
      {/*        </Link>*/}
      {/*    )}*/}
      {/*</div>*/}
      <div className="flex flex-row justify-between items-center h-[80px] w-full bg-linear-header-media p-5">
        <div className="flex items-center">
          <h1 className="text-[30px] md:text-[50px]">Matches</h1>
        </div>
        <div className="flex flex-row items-center">
          <div>
            <div className="flex items-center">
              <div className="font-chakra text-[#18062f] !text-right text-[12px] md:text-[14px]">
                <div className="select-wrapper">
                  <select
                    className="dropdown"
                    onChange={handleCompetitionChange}
                    value={curCompetition}
                  >
                    <option value="All">All competitions</option>
                    {competitions.map((sel, idx) => {
                      if (sel) {
                        return (
                          <option value={sel} key={idx}>
                            {sel}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <button
            className="w-[30px] h-[30px] flex justify-center items-center ml-[10px]"
            style={{ border: "1px solid #D2FF00" }}
            onClick={openShareModal}
          >
            <img src="../img/public/Union.png" />
          </button>
        </div>
      </div>
      <>
        <ShareModal
          isOpen={isShareModalOpen}
          onRequestClose={closeShareModal}
          url={window.location.href}
        />
      </>

      <div className="bg-header p-4 flex flex-row items-center gap-6 h-[50px] text-[18px] mb-[2px]">
        <div
          // className='pointer'
          style={{
            height: "50px",
            lineHeight: "50px",
            borderBottom: matchType === "live" ? "5px solid #D2FF00" : "",
            opacity: matchType === "live" ? 1 : 0.5,
            cursor: "pointer",
          }}
          onClick={() => setMatchType("live")}
        >
          LIVE &amp; UPCOMING
        </div>
        <div
          style={{
            height: "50px",
            lineHeight: "50px",
            borderBottom: matchType === "finished" ? "5px solid #D2FF00" : "",
            opacity: matchType === "finished" ? 1 : 0.5,
            cursor: "pointer",
          }}
          onClick={() => setMatchType("finished")}
        >
          FINISHED
        </div>
      </div>

      <div className="bg-header">
        {matchType === "live" ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full pt-[10px]">
              {matches
                .filter(
                  (match) =>
                    curCompetition === "All" ||
                    match.competition === curCompetition
                )
                .map((match) => (
                  <div className="p-1" key={match.id}>
                    <MatchCard
                      match_id={`${match.id}`}
                      tournament={`${match.competition}`}
                      matchTime={`${match.localTime}`}
                      homeTeamName={`${match.teams[0]}`}
                      awayTeamName={`${match.teams[1]}`}
                      homeTeamScore={`${liveScore[match.id]?.homescore || "-"}`}
                      awayTeamScore={`${liveScore[match.id]?.awayscore || "-"}`}
                      batnow={`${liveScore[match.id]?.batnow || ""}`}
                      diff={`${match.diff}`}
                      watchalongscheduled={`${match.wal}`}
                      stream={`${match.stream}`}
                      isTraderEnabled={isTraderEnabled}
                      isSquaresEnabled={isSquaresEnabled}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            {/* <div className='mt-[30px] mb-[10px] flex'>
                    <div className='font-chakra text-white text-[16px] mr-[10px] pt-[8px]'>
                        COMPETITION:
                    </div>
                    <div className='font-chakra text-[#18062f] !text-right text-[12px] md:text-[14px]'>
                        <div className="select-wrapper">
                            <select className='dropdown' onChange={handleFinishedCompetitionChange}
                                    value={curFinishedCompetition}>
                                <option value='All'>All</option>
                                {finishedCompetitions.map((sel, idx) => {
                                    if (sel) {
                                        return (<option value={sel}>
                                            {sel}
                                        </option>);
                                    }
                                })}
                            </select>
                        </div>
                    </div>
                </div> */}

            <div className="md:flex md:flex-wrap w-full pt-[10px]">
              {finishedMatches.length > 0 &&
                finishedMatches.map((match, index) =>
                  curCompetition === "All" ||
                  match.competition.substring(0, curCompetition.length) ===
                    curCompetition
                    ? (match.status.substring(0, 14) === "Match Complete" ||
                        match.status.substring(0, 10) === "Super Over") && (
                        <div className="w-full md:w-1/3 p-1" key={match.id}>
                          <MatchCard
                            match_id={`${match.id}`}
                            tournament={`${match.competition}`}
                            matchTime={`${match.formattedDate}`}
                            homeTeamName={`${match.teams[0]}`}
                            awayTeamName={`${match.teams[1]}`}
                            homeTeamScore={`${match.scores[0].score || ""}`}
                            awayTeamScore={`${match.scores[1].score || ""}`}
                            batnow=""
                            diff={`${match.status}`}
                            complete="true"
                          />
                        </div>
                      )
                    : null
                )}
            </div>

            <div className="mt-[30px] justify-center flex">
              {loadingMore ? (
                <div className="h-[50px] pt-[29px] w-full font-chakra text-white text-[14px] text-center">
                  Loading...
                </div>
              ) : (
                <div
                  className="h-[50px] w-[330px] top-[673px] bg-[#d2ff00] text-anton text-[16px] text-[#1b062d] text-center pt-[17px] px-[20px] inline-block cursor-pointer"
                  onClick={() => handleLoadMore()}
                >
                  LOAD MORE
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Matches;
